var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "p-button-rounded menuButton",
          attrs: { id: "menuButton", iconPos: "right" },
          on: {
            click: function ($event) {
              return _vm.openMenu()
            },
          },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
          _c("i", { staticClass: "onpoint-caret-down iconButton" }),
        ]
      ),
      _vm.menuTemplate
        ? _c("Card", {
            staticClass: "menu",
            attrs: { id: "menu" },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm.auxiliarText
                        ? _c(
                            "div",
                            { staticClass: "pr-1 pl-1 auxiliar-text" },
                            [_c("p", [_vm._v(_vm._s(_vm.auxiliarText))])]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "buttonList" },
                          _vm._l(_vm.menuItems, function (item, i) {
                            return _c(
                              "div",
                              { key: i },
                              [
                                (
                                  item.authorization
                                    ? item.authorization()
                                    : true
                                )
                                  ? _c(
                                      "Button",
                                      {
                                        staticClass:
                                          "itemButton mt-1 mb-1 pl-0 pr-1",
                                        on: {
                                          click: function ($event) {
                                            item.action ? item.action() : null,
                                              (_vm.menuTemplate = false)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid grid-cols-12 grid-rows-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "itemIcon col-span-2",
                                              },
                                              [
                                                _c("i", {
                                                  class:
                                                    item.icon + " itemIcon",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "itemTitle col-start-3 col-span-10",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.title) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "itemSubtitle col-start-3 col-span-10",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.subtitle) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3475542428
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }