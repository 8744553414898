<template>
  <div>
    <Button
      id="menuButton"
      class="p-button-rounded menuButton"
      iconPos="right"
      @click="openMenu()"
    >
      {{ label }}
      <i class="onpoint-caret-down iconButton"></i>
    </Button>

    <Card v-if="menuTemplate" class="menu" id="menu">
      <template #title>
        <div v-if="auxiliarText" class="pr-1 pl-1 auxiliar-text">
          <p>{{ auxiliarText }}</p>
        </div>
      </template>
      <template #content>       
        <div>
          <div class="buttonList">
            <div v-for="(item, i) in menuItems" :key="i">              
              <Button                
                class="itemButton mt-1 mb-1 pl-0 pr-1"
                @click="item.action?item.action():null,menuTemplate=false"
                v-if="item.authorization?item.authorization():true"
              >              
                <div class="grid grid-cols-12 grid-rows-2">
                  <div class="itemIcon col-span-2">
                    <i :class="item.icon + ' itemIcon'"></i>
                  </div>
                  <div class="itemTitle col-start-3 col-span-10">
                    {{ item.title }}
                  </div>
                  <div class="itemSubtitle col-start-3 col-span-10">
                    {{ item.subtitle }}
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Button from "primevue/button";
import Card from "primevue/card";
export default {
  props: {
    label: String,
    menuItems: Array,
    auxiliarText: String,    
  },
  components: {
    Button,
    Card,
  },
  data() {
    return {
      menuTemplate: false,
    };
  },
  methods: {
    openMenu() {
      this.menuTemplate = !this.menuTemplate;
    },
    cssStyleConfigs() {
      let heightButtonMenu = `${
        window.document.getElementById("menuButton").offsetHeight
      }px`;
      let widhtButtonMenu = `${
        window.document.getElementById("menuButton").offsetWidth
      }px`;
      document.documentElement.style.setProperty(
        "--widhtButtonMenu",
        widhtButtonMenu
      );
      document.documentElement.style.setProperty(
        "--heightButtonMenu",
        heightButtonMenu
      );
    },
  },
  mounted() {
    this.cssStyleConfigs();
  },
};
</script>

<style lang="scss" >
:root {
  --widhtButtonMenu: 0px;
  --heightButtonMenu: 0px;
}
.iconButton {
  position: relative;
  top: 2px;
}
.menu {
  z-index: 1000;
  position: absolute;
  min-width: var(--widhtButtonMenu);
  max-width: calc(var(--widhtButtonMenu) * 2);
  border-radius: 15px;
  margin-top: calc(var(--heightButtonMenu) + 10px);
}
.itemButton {
  min-width: 100%;
  background: white;
  border: solid, 1px, #d1d5db;
  color: rgba(var(--vs-primary), 1);
  border-radius: 8px !important;
}
.itemTitle {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  position: relative;
  right: 10px;
  text-align: left;
}
.itemSubtitle {
  text-align: left;
  color: rgba(55, 65, 81, 1);
  font-size: 10px;
  font-weight: 500;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: flex-start;
  position: relative;
  right: 10px;
}
.itemButton:hover {
  .itemSubtitle {
    color: white;
  }
}
.itemIcon {
  font-size: 20px;
}
.auxiliar-text {
  font-weight: 500;
  font-size: 12px;
  color: rgba(107, 114, 128, 1);
  text-align: center;
  margin-bottom: 5px;
}
</style>